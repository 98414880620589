import React from 'react'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import { PageNotFound } from '@arch-log/webapp.shared/Errors'

/**
 */
const Page = () => <PageNotFound />

/**
 */
export default Page

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}
